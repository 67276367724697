/* eslint-disable global-require */
import _get from 'lodash/get'
import { createNamespacedHelpers } from 'vuex'
import { CURRENCY } from '@/constants'

const { mapGetters } = createNamespacedHelpers('auth')
export default {
  computed: {
    ...mapGetters(['generalConfigs', 'user']),
    listTargetMarket() {
      return _get(this.generalConfigs, 'targetMarkets', [])
    },

    listTimeZone() {
      return _get(this.generalConfigs, 'timezones', [])
    },

    listCurrency() {
      return _get(this.generalConfigs, 'currencyCodes', CURRENCY)
    },

    paymentMethods() {
      return _get(this.generalConfigs, 'availablePaymentMethods', [])
    },

    setupCostFee() {
      return _get(this.generalConfigs, 'setupCostFee')
    },

    isSetupCostModel() {
      return !!_get(this.generalConfigs, 'setupCostFee')
    },

    paymentGateFee() {
      return _get(this.generalConfigs, 'paymentGateFee', [])
    },

    USDToVNDRate() {
      return _get(this.generalConfigs, ['exchangeCurrency', 'USDToVNDRate'], 23200)
    },

    USDToPKRRate() {
      return _get(this.generalConfigs, ['exchangeCurrency', 'USDToPKRRate'], 295)
    },

    USDToEURRate() {
      return _get(this.generalConfigs, ['exchangeCurrency', 'USDToEURRate'], 0.909090909)
    },

    minTopUp() {
      return _get(this.user, ['data', 'plans', 'minTopup'], Number(process.env.VUE_APP_MIN_TOP_UP))
    },

    maxTopUpAdsAccount() {
      return _get(this.generalConfigs, ['transferAdsAccountValidation', 'MAX_TOPUP_ADS_ACCOUNT'], 10000)
    },

    minTopUpAdsAccount() {
      return _get(this.generalConfigs, ['transferAdsAccountValidation', 'MIN_TOPUP_ADS_ACCOUNT'], 10)
    },

    maxWithdrawAdsAccount() {
      return _get(this.generalConfigs, ['transferAdsAccountValidation', 'MAX_WITHDRAW_ADS_ACCOUNT'], 10000)
    },

    minWithdrawAdsAccount() {
      return _get(this.generalConfigs, ['transferAdsAccountValidation', 'MIN_WITHDRAW_ADS_ACCOUNT'], 0.1)
    },
  },
}
